import { default as indexO9LXXb7aplMeta } from "/opt/buildhome/repo/layers/accessControl/pages/access-control/permission/index.vue?macro=true";
import { default as index_45indeterminateHEy4xDoTg7Meta } from "/opt/buildhome/repo/layers/accessControl/pages/access-control/role/[id]/index-indeterminate.vue?macro=true";
import { default as indexZO1hBkeNMTMeta } from "/opt/buildhome/repo/layers/accessControl/pages/access-control/role/[id]/index.vue?macro=true";
import { default as indexhxMVXWWsmJMeta } from "/opt/buildhome/repo/layers/accessControl/pages/access-control/role/index.vue?macro=true";
import { default as bank_45accountl3g9aljJWqMeta } from "/opt/buildhome/repo/layers/account/pages/account/index/bank-account.vue?macro=true";
import { default as cash_45advanceJCz019biKRMeta } from "/opt/buildhome/repo/layers/account/pages/account/index/cash-advance.vue?macro=true";
import { default as certificationI1sOFR7Jh8Meta } from "/opt/buildhome/repo/layers/account/pages/account/index/certification.vue?macro=true";
import { default as contact_45informationsmkHhb3RhRMeta } from "/opt/buildhome/repo/layers/account/pages/account/index/contact-information.vue?macro=true";
import { default as educations9MrO6Ss5jMeta } from "/opt/buildhome/repo/layers/account/pages/account/index/education.vue?macro=true";
import { default as emergency_45contactd6EL5JXiq0Meta } from "/opt/buildhome/repo/layers/account/pages/account/index/emergency-contact.vue?macro=true";
import { default as notification84aBhtn1wNMeta } from "/opt/buildhome/repo/layers/account/pages/account/index/notification.vue?macro=true";
import { default as overtime5NswaR4wYvMeta } from "/opt/buildhome/repo/layers/account/pages/account/index/overtime.vue?macro=true";
import { default as photo_45signature6YdUZKw5YRMeta } from "/opt/buildhome/repo/layers/account/pages/account/index/photo-signature.vue?macro=true";
import { default as profileq8Yvm3NTM1Meta } from "/opt/buildhome/repo/layers/account/pages/account/index/profile.vue?macro=true";
import { default as reimbursementanaZIrfWK8Meta } from "/opt/buildhome/repo/layers/account/pages/account/index/reimbursement.vue?macro=true";
import { default as report_45overtimeUyaqfedKNkMeta } from "/opt/buildhome/repo/layers/account/pages/account/index/report-overtime.vue?macro=true";
import { default as security7LPD8n07w5Meta } from "/opt/buildhome/repo/layers/account/pages/account/index/security.vue?macro=true";
import { default as indexCIdL7Ad2kYMeta } from "/opt/buildhome/repo/layers/account/pages/account/index.vue?macro=true";
import { default as indexuxTX57bnMbMeta } from "/opt/buildhome/repo/layers/company/pages/admin/companies/index.vue?macro=true";
import { default as bank_45accountzjS9s7lsaVMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/bank-account.vue?macro=true";
import { default as clientsIIcAFgo0LZMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/clients.vue?macro=true";
import { default as departmentKC1QUwiRJSMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/department.vue?macro=true";
import { default as employees3CScksj9dfMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/employees.vue?macro=true";
import { default as estimate_45costaTRXhxGoXAMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/estimate-cost.vue?macro=true";
import { default as indexxaSIpQEjFPMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/index.vue?macro=true";
import { default as logoLwPqVd7BYqMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/logo.vue?macro=true";
import { default as milestone_45templateYoSEtQM1dFMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/milestone-template.vue?macro=true";
import { default as positionm0k9Qv78pyMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/position.vue?macro=true";
import { default as project_45flow7MrpUUjeD3Meta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/project-flow.vue?macro=true";
import { default as salesei2EUCHPowMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/sales.vue?macro=true";
import { default as scopesmiMSgxetgaMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/scopes.vue?macro=true";
import { default as servicesW50Yoh0YGuMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/services.vue?macro=true";
import { default as createSSlGIM4qAxMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/setting/create.vue?macro=true";
import { default as indexG42Dl9COdeMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/setting/index.vue?macro=true";
import { default as update_45_91settingId_93IkahqFHmO9Meta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/setting/update-[settingId].vue?macro=true";
import { default as tax_45companyMlIGHN5nYTMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/tax-company.vue?macro=true";
import { default as indexf1lOQQHhQPMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index.vue?macro=true";
import { default as update1LTOHk5N6bMeta } from "/opt/buildhome/repo/layers/company/pages/admin/company/[id]/update.vue?macro=true";
import { default as indexyaE5b5nHEYMeta } from "/opt/buildhome/repo/layers/adminPanel/pages/admin/index.vue?macro=true";
import { default as index8l7PQr8psKMeta } from "/opt/buildhome/repo/layers/master/pages/admin/master/bank/index.vue?macro=true";
import { default as indexRJojE6eHXCMeta } from "/opt/buildhome/repo/layers/master/pages/admin/master/city/index.vue?macro=true";
import { default as index6jrKgtwk0wMeta } from "/opt/buildhome/repo/layers/master/pages/admin/master/country/index.vue?macro=true";
import { default as indexOooUlGok6FMeta } from "/opt/buildhome/repo/layers/master/pages/admin/master/currency/index.vue?macro=true";
import { default as indexuqyetRLMZ0Meta } from "/opt/buildhome/repo/layers/master/pages/admin/master/province/index.vue?macro=true";
import { default as indexaSvjlz3OfDMeta } from "/opt/buildhome/repo/layers/master/pages/admin/master/questioner/index.vue?macro=true";
import { default as indexzcAqnt2IUzMeta } from "/opt/buildhome/repo/layers/adminPanel/pages/admin/users/index.vue?macro=true";
import { default as indexnJv89sdaRWMeta } from "/opt/buildhome/repo/layers/application/pages/application/calculator-pph-21/index.vue?macro=true";
import { default as indexUnUxFYn2eoMeta } from "/opt/buildhome/repo/layers/application/pages/application/income-tax/index.vue?macro=true";
import { default as indexlsjjncMD4UMeta } from "/opt/buildhome/repo/layers/application/pages/application/tax-slip/[id]/index.vue?macro=true";
import { default as indexruyc5QTVHTMeta } from "/opt/buildhome/repo/layers/application/pages/application/tax-slip/index.vue?macro=true";
import { default as indexW5NqhcVkNHMeta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/asset-location/index.vue?macro=true";
import { default as indexZ17SM3n9fVMeta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/asset-type/index.vue?macro=true";
import { default as contactswd9C2j3cBKMeta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/asset/[id]/index/contacts.vue?macro=true";
import { default as index527DGeuMt7Meta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/asset/[id]/index/index.vue?macro=true";
import { default as notesE35H46RwX8Meta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/asset/[id]/index/notes.vue?macro=true";
import { default as indexgVz1MM0tIzMeta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/asset/[id]/index.vue?macro=true";
import { default as update6wUgf2io3tMeta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/asset/[id]/update.vue?macro=true";
import { default as create9zk3VBMCKQMeta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/asset/create.vue?macro=true";
import { default as indexwGuTHjXa2NMeta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/asset/index.vue?macro=true";
import { default as indexuayxjjsxswMeta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/detail/[code]/index.vue?macro=true";
import { default as accountsrKN1Zd8wjQMeta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/vendor/[id]/index/accounts.vue?macro=true";
import { default as addressFJLxVVjj6RMeta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/vendor/[id]/index/address.vue?macro=true";
import { default as index5VTtwd7YnJMeta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/vendor/[id]/index/index.vue?macro=true";
import { default as indexiPQFmj5YZ8Meta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/vendor/[id]/index.vue?macro=true";
import { default as indexAlf3oIFm7rMeta } from "/opt/buildhome/repo/layers/asset/pages/asset-management/vendor/index.vue?macro=true";
import { default as forgot_45passwordIAC9O4ui0NMeta } from "/opt/buildhome/repo/layers/auth/pages/auth/forgot-password.vue?macro=true";
import { default as logine3cetUJ75gMeta } from "/opt/buildhome/repo/layers/auth/pages/auth/login.vue?macro=true";
import { default as _91token_93dIirawLSgkMeta } from "/opt/buildhome/repo/layers/auth/pages/auth/password/reset/[token].vue?macro=true";
import { default as bank_45accountsHlGnJUIOBMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/bank-account.vue?macro=true";
import { default as clientsj9LicYjWLzMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/clients.vue?macro=true";
import { default as departmentK3daB9WQjnMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/department.vue?macro=true";
import { default as employeesWnNXaqk4GmMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/employees.vue?macro=true";
import { default as estimate_45costDYcqRvc1SbMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/estimate-cost.vue?macro=true";
import { default as index5CCKm1YDZgMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/index.vue?macro=true";
import { default as logoHJz8AWDJ8NMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/logo.vue?macro=true";
import { default as milestone_45templateCTRHg7DOGiMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/milestone-template.vue?macro=true";
import { default as positiond9vfRqK3pkMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/position.vue?macro=true";
import { default as project_45flowh7eIqyR2MdMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/project-flow.vue?macro=true";
import { default as salesHiMfpJWStSMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/sales.vue?macro=true";
import { default as scopesisTVHQJvEiMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/scopes.vue?macro=true";
import { default as servicesidGhy98WE2Meta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/services.vue?macro=true";
import { default as create7Zh4G2DpJGMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/setting/create.vue?macro=true";
import { default as index39djdClOSqMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/setting/index.vue?macro=true";
import { default as update_45_91settingId_93p8mzgtoj6MMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/setting/update-[settingId].vue?macro=true";
import { default as tax_45companyLdkK1JQYPeMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index/tax-company.vue?macro=true";
import { default as index3yLO2UGHHKMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/index.vue?macro=true";
import { default as update0puOwCjmwqMeta } from "/opt/buildhome/repo/layers/company/pages/company/[id]/update.vue?macro=true";
import { default as createuw6twzNNmvMeta } from "/opt/buildhome/repo/layers/company/pages/company/create.vue?macro=true";
import { default as estimate_45costSOH364ta9dMeta } from "/opt/buildhome/repo/layers/company/pages/company/estimate-cost.vue?macro=true";
import { default as indexKaWMa5iUe2Meta } from "/opt/buildhome/repo/layers/company/pages/company/index.vue?macro=true";
import { default as indexS48M7cnUzIMeta } from "/opt/buildhome/repo/layers/company/pages/company/milestone-template/[id]/index/index.vue?macro=true";
import { default as milestoneJYC7WDVHwsMeta } from "/opt/buildhome/repo/layers/company/pages/company/milestone-template/[id]/index/milestone.vue?macro=true";
import { default as indexwIdbbxYIedMeta } from "/opt/buildhome/repo/layers/company/pages/company/milestone-template/[id]/index.vue?macro=true";
import { default as createQNLpTURzqfMeta } from "/opt/buildhome/repo/layers/company/pages/company/milestone-template/create.vue?macro=true";
import { default as project_45status7JRh44DlqbMeta } from "/opt/buildhome/repo/layers/company/pages/company/project-status.vue?macro=true";
import { default as scope7NsnhCauMhMeta } from "/opt/buildhome/repo/layers/company/pages/company/scope.vue?macro=true";
import { default as indexOmgoQ7GuIRMeta } from "/opt/buildhome/repo/layers/company/pages/company/service/[id]/index.vue?macro=true";
import { default as indexoXBDz8bHz1Meta } from "/opt/buildhome/repo/layers/company/pages/company/service/index.vue?macro=true";
import { default as chartsJCmnYT2VdeMeta } from "/opt/buildhome/repo/pages/dashboard/index/charts.vue?macro=true";
import { default as index7e60nzuopaMeta } from "/opt/buildhome/repo/pages/dashboard/index/index.vue?macro=true";
import { default as indexSEhdEZkNMjMeta } from "/opt/buildhome/repo/pages/dashboard/index.vue?macro=true";
import { default as indexPJOwJ724lgMeta } from "/opt/buildhome/repo/layers/humanResource/pages/direct-assignment/[id]/index/index.vue?macro=true";
import { default as logsAtqBPi3a86Meta } from "/opt/buildhome/repo/layers/humanResource/pages/direct-assignment/[id]/index/logs.vue?macro=true";
import { default as timesheetRfIsRGLGp7Meta } from "/opt/buildhome/repo/layers/humanResource/pages/direct-assignment/[id]/index/timesheet.vue?macro=true";
import { default as indexFO4WuaLuArMeta } from "/opt/buildhome/repo/layers/humanResource/pages/direct-assignment/[id]/index.vue?macro=true";
import { default as indextHdwCvA4TNMeta } from "/opt/buildhome/repo/layers/humanResource/pages/direct-assignment/index.vue?macro=true";
import { default as indexYGrFtGwCe8Meta } from "/opt/buildhome/repo/layers/finance/pages/finance/cash-advance/detail/[id]/index.vue?macro=true";
import { default as _91category_93pL8d3Qx7FxMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/cash-advance/index/[category].vue?macro=true";
import { default as indexd4VYHR9F6gMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/cash-advance/index/index.vue?macro=true";
import { default as indexhWD3PxpvgHMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/cash-advance/index.vue?macro=true";
import { default as index4uz8nNAH60Meta } from "/opt/buildhome/repo/layers/finance/pages/finance/fund-application/[id]/index/index.vue?macro=true";
import { default as indexaVgCYtJbjVMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/fund-application/[id]/index.vue?macro=true";
import { default as updateb9JClkHDgqMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/fund-application/[id]/update.vue?macro=true";
import { default as create8glc3GeQFkMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/fund-application/create.vue?macro=true";
import { default as indexS644bUT4IxMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/fund-application/index.vue?macro=true";
import { default as indexpABIpyfH9wMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/index/index.vue?macro=true";
import { default as reminderk2E9JdGLeRMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/index/reminder.vue?macro=true";
import { default as summarylJhgrOh7S6Meta } from "/opt/buildhome/repo/layers/finance/pages/finance/index/summary.vue?macro=true";
import { default as indexo70k6PVg1JMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/index.vue?macro=true";
import { default as indexNyLdAtVpN8Meta } from "/opt/buildhome/repo/layers/finance/pages/finance/invoice-counter/index.vue?macro=true";
import { default as editOfK2he0LHrMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/invoice/[id]/edit.vue?macro=true";
import { default as indexfvbc0IaZUWMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/invoice/[id]/index/index.vue?macro=true";
import { default as logsMXoerMLtguMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/invoice/[id]/index/logs.vue?macro=true";
import { default as reminderyt0OdFXlLZMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/invoice/[id]/index/reminder.vue?macro=true";
import { default as index3uLZbQraghMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/invoice/[id]/index.vue?macro=true";
import { default as createUIKfhU7SVcMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/invoice/create.vue?macro=true";
import { default as indexnnNnNPedskMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/invoice/index/index.vue?macro=true";
import { default as pendingYyjv1Hhfr4Meta } from "/opt/buildhome/repo/layers/finance/pages/finance/invoice/index/pending.vue?macro=true";
import { default as settlementesvMuY4x21Meta } from "/opt/buildhome/repo/layers/finance/pages/finance/invoice/index/settlement.vue?macro=true";
import { default as indexzZGCRwUjVeMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/invoice/index.vue?macro=true";
import { default as index4rkWY7l6KjMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/payments/index.vue?macro=true";
import { default as indexroLxf0VEzFMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/promo/[id]/index.vue?macro=true";
import { default as createlKjZGxMH9ZMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/promo/create.vue?macro=true";
import { default as indexOMLL5J00NEMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/promo/index.vue?macro=true";
import { default as filesNXsug2w7vnMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/[id]/index/files.vue?macro=true";
import { default as index4ufQRocfn8Meta } from "/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/[id]/index/index.vue?macro=true";
import { default as logsZoB8HEN1DzMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/[id]/index/logs.vue?macro=true";
import { default as indexS3obwFT0N1Meta } from "/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/[id]/index.vue?macro=true";
import { default as updateUCx50jzIyNMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/[id]/update.vue?macro=true";
import { default as createjdpHHZtNy2Meta } from "/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/create.vue?macro=true";
import { default as indexAQi8wpuvkDMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/index.vue?macro=true";
import { default as indexnlJsOlbbkXMeta } from "/opt/buildhome/repo/layers/finance/pages/finance/request-invoice/index.vue?macro=true";
import { default as updatepXgM3eL6beMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/announcements/[id]/update.vue?macro=true";
import { default as createYlCpQscd2HMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/announcements/create.vue?macro=true";
import { default as indexScsH0JMqqyMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/announcements/index.vue?macro=true";
import { default as indexE6XPzcNi8ZMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/appraisal/index.vue?macro=true";
import { default as applicantsse7vOGbwTHMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/career/[id]/applicants.vue?macro=true";
import { default as updateg7AHjIXKf7Meta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/career/[id]/update.vue?macro=true";
import { default as indexJaXd43NeGmMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/career/applicant/[id]/index.vue?macro=true";
import { default as createLgz7s6QLMIMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/career/create.vue?macro=true";
import { default as indexovHX6yVkUIMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/career/index.vue?macro=true";
import { default as indexakNVN1KmKmMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/competency/[id]/index.vue?macro=true";
import { default as indexzxRDnhz6WLMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/competency/assessment/[id]/index.vue?macro=true";
import { default as createUlGSOiUS3uMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/competency/create.vue?macro=true";
import { default as indexPZREMzutrQMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/competency/index.vue?macro=true";
import { default as indexW0Rjh7Ahg1Meta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/dashboard/index.vue?macro=true";
import { default as indexUelPo4tFdNMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/department/index.vue?macro=true";
import { default as indexrlYTdfddQXMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee-status/index.vue?macro=true";
import { default as access_45controlX02ZkcL9jUMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/access-control.vue?macro=true";
import { default as bank_45account6UYE2taHUJMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/bank-account.vue?macro=true";
import { default as certificationopEVFcnRsSMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/certification.vue?macro=true";
import { default as contact_45informationOHwh4SNNXnMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/contact-information.vue?macro=true";
import { default as educationr8ZUqjfIruMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/education.vue?macro=true";
import { default as emergency_45contact32WN23PuWyMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/emergency-contact.vue?macro=true";
import { default as indexnHrdqqYmexMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/index.vue?macro=true";
import { default as indicator_45assessorUhUlq6cbM6Meta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/indicator-assessor.vue?macro=true";
import { default as indicatorLx64PJTSVWMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/indicator.vue?macro=true";
import { default as overtimeRJskW1EfmzMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/overtime.vue?macro=true";
import { default as photo_45signature761nIR5sxdMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/photo-signature.vue?macro=true";
import { default as positions83Fc3fDgFOMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/positions.vue?macro=true";
import { default as report_45overtimeZp5cyuHTxtMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/report-overtime.vue?macro=true";
import { default as salaryQWYFMk0FOlMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/salary.vue?macro=true";
import { default as securitybehX0FXUAtMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/security.vue?macro=true";
import { default as tax_45insurancesmyQCMetOrrMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/tax-insurances.vue?macro=true";
import { default as indexPE88KaPF3kMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index.vue?macro=true";
import { default as createXlGcAPdeYEMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/create.vue?macro=true";
import { default as indexeJeGsDTXnfMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/index.vue?macro=true";
import { default as indexGz3963p50fMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/goal-calendar/index.vue?macro=true";
import { default as indexrPhUvqpy41Meta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/goal-type/index.vue?macro=true";
import { default as indexu5Z2TqYMH8Meta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/holidays/index.vue?macro=true";
import { default as indexqaMJF7i32fMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/indicator/[id]/index.vue?macro=true";
import { default as index8aggDl4ziZMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/indicator/index.vue?macro=true";
import { default as indexw9TfkvbmhoMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/organizational-chart/index.vue?macro=true";
import { default as hr_45validationAYGkTmnU4vMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/overtime/[id]/index/hr-validation.vue?macro=true";
import { default as indexdVc0nlYLekMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/overtime/[id]/index/index.vue?macro=true";
import { default as logsGnVADqRU0bMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/overtime/[id]/index/logs.vue?macro=true";
import { default as indexn4qaqvJ0q3Meta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/overtime/[id]/index.vue?macro=true";
import { default as indexKtYOXm86ysMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/overtime/index.vue?macro=true";
import { default as indexzz5yhBVciwMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/position/index.vue?macro=true";
import { default as indexFiNKIJ4tbpMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/setting-indicator/index.vue?macro=true";
import { default as indexPCsfI3DlaJMeta } from "/opt/buildhome/repo/layers/humanResource/pages/human-resource/track-goals/index.vue?macro=true";
import { default as indexydBV1ePN8KMeta } from "/opt/buildhome/repo/layers/inadata/pages/inadata/glosarium/[q]/index.vue?macro=true";
import { default as _91q_93yrNqXkKukSMeta } from "/opt/buildhome/repo/layers/inadata/pages/inadata/glosarium/[q].vue?macro=true";
import { default as indexB2dbf0EeoaMeta } from "/opt/buildhome/repo/layers/inadata/pages/inadata/glosarium/index.vue?macro=true";
import { default as index9pselWOFCVMeta } from "/opt/buildhome/repo/layers/inadata/pages/inadata/peraturan-pajak/index.vue?macro=true";
import { default as indexeqefniI6d9Meta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/chat-rag-ayat/index.vue?macro=true";
import { default as index4fCyP9yOORMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/chat-rag/index.vue?macro=true";
import { default as index42HtzguMpdMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/chat-stream/[id]/index.vue?macro=true";
import { default as indexaxBk4vR1rbMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/chat-stream/index.vue?macro=true";
import { default as indexSm6Xeb8IfmMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/chat/index.vue?macro=true";
import { default as indexA50OUKAz6rMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/master-system-prompt/index.vue?macro=true";
import { default as indexYFfzk4XiAlMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/score-detail/[id]/index.vue?macro=true";
import { default as indexgVwSrKVdBCMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/score/[id]/index.vue?macro=true";
import { default as indexASnXqmd1j9Meta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/score/index.vue?macro=true";
import { default as indexUz9ayCrcZiMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-chat-pasal-ayat-v2/[id]/index.vue?macro=true";
import { default as indexAvckxVyvQrMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-chat-pasal-ayat-v2/index.vue?macro=true";
import { default as indexlizF0aACggMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-chat-pasal-ayat/[id]/index.vue?macro=true";
import { default as indexcu1ILCClYiMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-chat-pasal-ayat/index.vue?macro=true";
import { default as indexeM0t3dtcCGMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-chat/[id]/index.vue?macro=true";
import { default as indexSBz5GizIg8Meta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-chat/index.vue?macro=true";
import { default as indexqLmzEu50wEMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-question/[id]/index.vue?macro=true";
import { default as indexT2LHTevMoiMeta } from "/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-question/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as addressKN9aaa4TJ3Meta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/address.vue?macro=true";
import { default as contactsz3aoOqGjKiMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/contacts.vue?macro=true";
import { default as filesZW6c03amuRMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/files.vue?macro=true";
import { default as financialXKkFkyd9fVMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/financial.vue?macro=true";
import { default as indexW1oVlz3Tg9Meta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/index.vue?macro=true";
import { default as invoicesHGA7AyCss3Meta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/invoices.vue?macro=true";
import { default as notesn0AptSPh0mMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/notes.vue?macro=true";
import { default as index4cykxiNcoxMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/projects/[status]/index.vue?macro=true";
import { default as questioner16pK4a9QOyMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/questioner.vue?macro=true";
import { default as indexuLES1ucib3Meta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index.vue?macro=true";
import { default as indexG3Me9oOE4vMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/index.vue?macro=true";
import { default as indexJcMhNUUy6iMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/dashboard/index.vue?macro=true";
import { default as clientsszuTK9FEV5Meta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/sales/[id]/index/clients.vue?macro=true";
import { default as feesqbjpxtnuEBMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/sales/[id]/index/fees.vue?macro=true";
import { default as indexhlwOyoT7VDMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/sales/[id]/index/index.vue?macro=true";
import { default as indexR2I64KnnomMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/sales/[id]/index/projects/[status]/index.vue?macro=true";
import { default as indexSAf3ZsxsBiMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/sales/[id]/index.vue?macro=true";
import { default as indexrD1P5FYL33Meta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/sales/index.vue?macro=true";
import { default as creategjCqjm1kqjMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/create.vue?macro=true";
import { default as additional159l24TfUwMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/detail/[id]/index/additional.vue?macro=true";
import { default as email_45marketingEqgcqp55zaMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/detail/[id]/index/email-marketing.vue?macro=true";
import { default as indexvSKXBzUV9vMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/detail/[id]/index/index.vue?macro=true";
import { default as invoicesXiSBsFHNgaMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/detail/[id]/index/invoices.vue?macro=true";
import { default as participantkpC0C9g8AqMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/detail/[id]/index/participant.vue?macro=true";
import { default as index59l9QDy70KMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/detail/[id]/index.vue?macro=true";
import { default as indexM1hWScGAoeMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/index.vue?macro=true";
import { default as indexDBgBGhK2N2Meta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/update/[id]/index.vue?macro=true";
import { default as creater4J33WROgFMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/speaker-host/create.vue?macro=true";
import { default as indexhEarXdVIxqMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/speaker-host/index.vue?macro=true";
import { default as index1ULUcSHXtnMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/speaker-host/update/[id]/index.vue?macro=true";
import { default as createwfCwXE2QJpMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/template-questioner/[id]/index/create.vue?macro=true";
import { default as indexC8zUwx8jO3Meta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/template-questioner/[id]/index/index.vue?macro=true";
import { default as updateSFrANI1cj5Meta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/template-questioner/[id]/index/update.vue?macro=true";
import { default as index4Wp1W9FhKhMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/template-questioner/[id]/index.vue?macro=true";
import { default as indexEuSBK0zwATMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/template-questioner/index.vue?macro=true";
import { default as _91id_93tTnzmF73vvMeta } from "/opt/buildhome/repo/layers/marketing/pages/marketing/template-questioner/list/update/[id].vue?macro=true";
import { default as indexWEePdDEV7OMeta } from "/opt/buildhome/repo/pages/media-manager/index.vue?macro=true";
import { default as indexTEcToEq4dyMeta } from "/opt/buildhome/repo/pages/meeting/index.vue?macro=true";
import { default as indexSSisXo3n6LMeta } from "/opt/buildhome/repo/pages/monitoring/consultation/index.vue?macro=true";
import { default as indexgp9JNP8IthMeta } from "/opt/buildhome/repo/pages/monitoring/project/index.vue?macro=true";
import { default as indexBtXDnxJmeMMeta } from "/opt/buildhome/repo/layers/humanResource/pages/overtime-letter/index.vue?macro=true";
import { default as profileBmmLCa6mlgMeta } from "/opt/buildhome/repo/pages/partner/account/index/profile.vue?macro=true";
import { default as securityZERfUOJNjiMeta } from "/opt/buildhome/repo/pages/partner/account/index/security.vue?macro=true";
import { default as indexAHQgiWSSTNMeta } from "/opt/buildhome/repo/pages/partner/account/index.vue?macro=true";
import { default as indexdRzKeCHhViMeta } from "/opt/buildhome/repo/pages/partner/client/[id]/index/index.vue?macro=true";
import { default as invoiceKHZnLjQZ0DMeta } from "/opt/buildhome/repo/pages/partner/client/[id]/index/invoice.vue?macro=true";
import { default as projecta6XSgvn9dkMeta } from "/opt/buildhome/repo/pages/partner/client/[id]/index/project.vue?macro=true";
import { default as indexw52uLYNcftMeta } from "/opt/buildhome/repo/pages/partner/client/[id]/index.vue?macro=true";
import { default as indexHee23xTo2WMeta } from "/opt/buildhome/repo/pages/partner/client/index.vue?macro=true";
import { default as index3OZP5C9nzFMeta } from "/opt/buildhome/repo/pages/partner/index.vue?macro=true";
import { default as indexozfrOoOxMeMeta } from "/opt/buildhome/repo/pages/partner/invoice/[id]/index.vue?macro=true";
import { default as indexiwfqVNFnNxMeta } from "/opt/buildhome/repo/pages/partner/invoice/index.vue?macro=true";
import { default as indexa55UjixauUMeta } from "/opt/buildhome/repo/pages/partner/project/[id]/index/index.vue?macro=true";
import { default as invoiceCT7o7afPlcMeta } from "/opt/buildhome/repo/pages/partner/project/[id]/index/invoice.vue?macro=true";
import { default as indexrVgBoXBJvQMeta } from "/opt/buildhome/repo/pages/partner/project/[id]/index.vue?macro=true";
import { default as indexsogoTtfzPcMeta } from "/opt/buildhome/repo/pages/partner/project/index.vue?macro=true";
import { default as privacy_45policypnUDKF1OY5Meta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as createeezDaq7Om5Meta } from "/opt/buildhome/repo/layers/project/pages/project/create.vue?macro=true";
import { default as assignmentLt8GEJHFcIMeta } from "/opt/buildhome/repo/layers/project/pages/project/dashboard/index/assignment.vue?macro=true";
import { default as change_45managerSxu3a3ff0YMeta } from "/opt/buildhome/repo/layers/project/pages/project/dashboard/index/change-manager.vue?macro=true";
import { default as indexmRhtoMtH8bMeta } from "/opt/buildhome/repo/layers/project/pages/project/dashboard/index/index.vue?macro=true";
import { default as reviewfu7gdD3BxUMeta } from "/opt/buildhome/repo/layers/project/pages/project/dashboard/index/review.vue?macro=true";
import { default as term_45of_45paymentngcUnRIMHHMeta } from "/opt/buildhome/repo/layers/project/pages/project/dashboard/index/term-of-payment.vue?macro=true";
import { default as indexS88EPSYaLHMeta } from "/opt/buildhome/repo/layers/project/pages/project/dashboard/index.vue?macro=true";
import { default as filesBdOYLHfXA0Meta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/contract/files.vue?macro=true";
import { default as indexvLsI1ugu0WMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/contract/index.vue?macro=true";
import { default as contracteL0zmbOlquMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/contract.vue?macro=true";
import { default as direct_45assignmenthXLQjHEqsrMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/direct-assignment.vue?macro=true";
import { default as due_45date_45historyWgKx9gw8biMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/due-date-history.vue?macro=true";
import { default as estimate_45costE0nChABykPMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/estimate-cost.vue?macro=true";
import { default as estimate_45timerut1aA7sMY6Meta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/estimate-timer.vue?macro=true";
import { default as external_45issueiM1T9M82mXMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/external-issue.vue?macro=true";
import { default as filesjDhSur7aRaMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/files.vue?macro=true";
import { default as follow_45upFMwoBdNzQwMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/follow-up.vue?macro=true";
import { default as indexaA7bzyiyyWMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/index.vue?macro=true";
import { default as internal_45issueAtTDqW4pBWMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/internal-issue.vue?macro=true";
import { default as invoicet1oUCxfitrMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/invoice.vue?macro=true";
import { default as logsiLhPwVkxQXMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/logs.vue?macro=true";
import { default as milestonei6700m6gvoMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/milestone.vue?macro=true";
import { default as project_45fee0iaxFfCiKYMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/project-fee.vue?macro=true";
import { default as realizationX3ukf6u6BtMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/realization.vue?macro=true";
import { default as teamss0frgHbPHcMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/teams.vue?macro=true";
import { default as term_45of_45paymentuj921xVTOUMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/term-of-payment.vue?macro=true";
import { default as timesheethhWC8Zr3WfMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/timesheet.vue?macro=true";
import { default as indexaC83OSx0KcMeta } from "/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index.vue?macro=true";
import { default as indexK2AA46JMSKMeta } from "/opt/buildhome/repo/layers/project/pages/project/index.vue?macro=true";
import { default as index7oUtEM1D09Meta } from "/opt/buildhome/repo/layers/project/pages/project/listing/[category]/index.vue?macro=true";
import { default as indextlzvNBSsHZMeta } from "/opt/buildhome/repo/layers/project/pages/project/recreate/[id]/index.vue?macro=true";
import { default as contractmGesN2cWlDMeta } from "/opt/buildhome/repo/layers/project/pages/project/update/[id]/contract.vue?macro=true";
import { default as indexLpjiUNU4E3Meta } from "/opt/buildhome/repo/layers/project/pages/project/update/[id]/index.vue?macro=true";
import { default as assetCCMcj2cIkYMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/asset.vue?macro=true";
import { default as clientxWzge36TwFMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/client.vue?macro=true";
import { default as employment_45statusxrgYM00qqCMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/employment-status.vue?macro=true";
import { default as financial_45ratiog9ePzaLPrdMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/financial-ratio.vue?macro=true";
import { default as financial_45statementzUHtRuao9cMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/financial-statement.vue?macro=true";
import { default as human_45resourceZ7WoDAGEfuMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/human-resource.vue?macro=true";
import { default as indexMs2yDpHdSkMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/index.vue?macro=true";
import { default as profit_45lossWDhnuBzaabMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/profit-loss.vue?macro=true";
import { default as projectVNtaJMr425Meta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/project.vue?macro=true";
import { default as quotation_45reportDrmKqhbCb0Meta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/quotation-report.vue?macro=true";
import { default as source_45clientgOQ4kKSJrFMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/source-client.vue?macro=true";
import { default as workloadimGbR4dY4jMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/workload.vue?macro=true";
import { default as indextj75HGCzCKMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index.vue?macro=true";
import { default as createaOi5C7YTwHMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/create.vue?macro=true";
import { default as indexzavOuph34bMeta } from "/opt/buildhome/repo/layers/reports/pages/report/monthly/index.vue?macro=true";
import { default as indexWNyUNAljT0Meta } from "/opt/buildhome/repo/layers/reports/pages/report/potential-invoice/index.vue?macro=true";
import { default as indexse8QvnGpyQMeta } from "/opt/buildhome/repo/layers/reports/pages/report/progress-project/index.vue?macro=true";
import { default as indexZ8uSEcKsgYMeta } from "/opt/buildhome/repo/layers/reports/pages/report/project-marginal-margin/index.vue?macro=true";
import { default as indexMJ36Z2kymsMeta } from "/opt/buildhome/repo/layers/reports/pages/report/project-per-status/index.vue?macro=true";
import { default as indexHT1WkThDZKMeta } from "/opt/buildhome/repo/layers/reports/pages/report/timer-consultant-per-project/index.vue?macro=true";
import { default as indexTeHmSRNXKDMeta } from "/opt/buildhome/repo/layers/reports/pages/report/timer-consultant/index.vue?macro=true";
import { default as indexKTDClEWk8xMeta } from "/opt/buildhome/repo/layers/reports/pages/report/timesheet/index.vue?macro=true";
import { default as indexf6t7fZYjldMeta } from "/opt/buildhome/repo/pages/restricted/index.vue?macro=true";
import { default as contact_45list4KyHP5gVe3Meta } from "/opt/buildhome/repo/pages/sleekflow/contact-list.vue?macro=true";
import { default as indexqSRR0bar1OMeta } from "/opt/buildhome/repo/pages/support/knowledge-base/index.vue?macro=true";
import { default as indextOLgD3uqShMeta } from "/opt/buildhome/repo/pages/support/package/index.vue?macro=true";
import { default as index2kdCgUhChlMeta } from "/opt/buildhome/repo/pages/support/package/send-document/index.vue?macro=true";
import { default as _91id_93EE53l9Caj7Meta } from "/opt/buildhome/repo/pages/support/package/track/[id].vue?macro=true";
import { default as indexXsoWST261bMeta } from "/opt/buildhome/repo/pages/support/ticket/index.vue?macro=true";
import { default as indexyIKtxfYOXPMeta } from "/opt/buildhome/repo/layers/taxCalculation/pages/tax-calculation/calculator-pph-21/index.vue?macro=true";
import { default as indexhVCFM4HuAcMeta } from "/opt/buildhome/repo/layers/taxCalculation/pages/tax-calculation/excel-template/[id]/index.vue?macro=true";
import { default as indexLp6vrLMxKDMeta } from "/opt/buildhome/repo/layers/taxCalculation/pages/tax-calculation/excel-template/index.vue?macro=true";
import { default as indexysV1bGWFnGMeta } from "/opt/buildhome/repo/layers/taxCalculation/pages/tax-calculation/upload-excel/[id]/index.vue?macro=true";
import { default as index94pmFVrvPbMeta } from "/opt/buildhome/repo/layers/taxCalculation/pages/tax-calculation/worksheet/[id]/index.vue?macro=true";
import { default as indexaHVOQwKkSIMeta } from "/opt/buildhome/repo/layers/taxCalculation/pages/tax-calculation/worksheet/index.vue?macro=true";
import { default as indextsLWkiYxEmMeta } from "/opt/buildhome/repo/layers/website/pages/website/expert/[id]/index.vue?macro=true";
import { default as createTQeRQpa218Meta } from "/opt/buildhome/repo/layers/website/pages/website/expert/create.vue?macro=true";
import { default as indexv3iIqV3gKtMeta } from "/opt/buildhome/repo/layers/website/pages/website/expert/index.vue?macro=true";
import { default as indexpO9bUpNa3dMeta } from "/opt/buildhome/repo/layers/website/pages/website/page-seo/index.vue?macro=true";
import { default as index0b3oVSBEJPMeta } from "/opt/buildhome/repo/layers/website/pages/website/post/[id]/index.vue?macro=true";
import { default as createDpdNZxzZXwMeta } from "/opt/buildhome/repo/layers/website/pages/website/post/create.vue?macro=true";
import { default as indexlAgOXvDkbNMeta } from "/opt/buildhome/repo/layers/website/pages/website/post/index.vue?macro=true";
import { default as indexEHOlfKjPwJMeta } from "/opt/buildhome/repo/layers/website/pages/website/service/[id]/index.vue?macro=true";
import { default as createWKFZmdiTvrMeta } from "/opt/buildhome/repo/layers/website/pages/website/service/create.vue?macro=true";
import { default as indexj8wcaRsiARMeta } from "/opt/buildhome/repo/layers/website/pages/website/service/index.vue?macro=true";
import { default as form_45checkoutHYkPTWlhVXMeta } from "/opt/buildhome/repo/pages/widget/pajakumkm/form-checkout.vue?macro=true";
import { default as indexWgcMtZt4AIMeta } from "/opt/buildhome/repo/pages/widget/pajakumkm/pay/[id]/index.vue?macro=true";
export default [
  {
    name: "access-control-permission",
    path: "/access-control/permission",
    meta: indexO9LXXb7aplMeta || {},
    component: () => import("/opt/buildhome/repo/layers/accessControl/pages/access-control/permission/index.vue")
  },
  {
    name: "access-control-role-id-index-indeterminate",
    path: "/access-control/role/:id()/index-indeterminate",
    meta: index_45indeterminateHEy4xDoTg7Meta || {},
    component: () => import("/opt/buildhome/repo/layers/accessControl/pages/access-control/role/[id]/index-indeterminate.vue")
  },
  {
    name: "access-control-role-id",
    path: "/access-control/role/:id()",
    meta: indexZO1hBkeNMTMeta || {},
    component: () => import("/opt/buildhome/repo/layers/accessControl/pages/access-control/role/[id]/index.vue")
  },
  {
    name: "access-control-role",
    path: "/access-control/role",
    meta: indexhxMVXWWsmJMeta || {},
    component: () => import("/opt/buildhome/repo/layers/accessControl/pages/access-control/role/index.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index.vue"),
    children: [
  {
    name: "account-index-bank-account",
    path: "bank-account",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/bank-account.vue")
  },
  {
    name: "account-index-cash-advance",
    path: "cash-advance",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/cash-advance.vue")
  },
  {
    name: "account-index-certification",
    path: "certification",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/certification.vue")
  },
  {
    name: "account-index-contact-information",
    path: "contact-information",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/contact-information.vue")
  },
  {
    name: "account-index-education",
    path: "education",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/education.vue")
  },
  {
    name: "account-index-emergency-contact",
    path: "emergency-contact",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/emergency-contact.vue")
  },
  {
    name: "account-index-notification",
    path: "notification",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/notification.vue")
  },
  {
    name: "account-index-overtime",
    path: "overtime",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/overtime.vue")
  },
  {
    name: "account-index-photo-signature",
    path: "photo-signature",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/photo-signature.vue")
  },
  {
    name: "account-index-profile",
    path: "profile",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/profile.vue")
  },
  {
    name: "account-index-reimbursement",
    path: "reimbursement",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/reimbursement.vue")
  },
  {
    name: "account-index-report-overtime",
    path: "report-overtime",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/report-overtime.vue")
  },
  {
    name: "account-index-security",
    path: "security",
    component: () => import("/opt/buildhome/repo/layers/account/pages/account/index/security.vue")
  }
]
  },
  {
    name: "admin-companies",
    path: "/admin/companies",
    meta: indexuxTX57bnMbMeta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/companies/index.vue")
  },
  {
    name: indexf1lOQQHhQPMeta?.name,
    path: "/admin/company/:id()",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index.vue"),
    children: [
  {
    name: "admin-company-id-index-bank-account",
    path: "bank-account",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/bank-account.vue")
  },
  {
    name: "admin-company-id-index-clients",
    path: "clients",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/clients.vue")
  },
  {
    name: "admin-company-id-index-department",
    path: "department",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/department.vue")
  },
  {
    name: "admin-company-id-index-employees",
    path: "employees",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/employees.vue")
  },
  {
    name: "admin-company-id-index-estimate-cost",
    path: "estimate-cost",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/estimate-cost.vue")
  },
  {
    name: "admin-company-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/index.vue")
  },
  {
    name: "admin-company-id-index-logo",
    path: "logo",
    meta: logoLwPqVd7BYqMeta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/logo.vue")
  },
  {
    name: "admin-company-id-index-milestone-template",
    path: "milestone-template",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/milestone-template.vue")
  },
  {
    name: "admin-company-id-index-position",
    path: "position",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/position.vue")
  },
  {
    name: "admin-company-id-index-project-flow",
    path: "project-flow",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/project-flow.vue")
  },
  {
    name: "admin-company-id-index-sales",
    path: "sales",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/sales.vue")
  },
  {
    name: "admin-company-id-index-scopes",
    path: "scopes",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/scopes.vue")
  },
  {
    name: "admin-company-id-index-services",
    path: "services",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/services.vue")
  },
  {
    name: "admin-company-id-index-setting-create",
    path: "setting/create",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/setting/create.vue")
  },
  {
    name: "admin-company-id-index-setting",
    path: "setting",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/setting/index.vue")
  },
  {
    name: "admin-company-id-index-setting-update-settingId",
    path: "setting/update-:settingId()",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/setting/update-[settingId].vue")
  },
  {
    name: "admin-company-id-index-tax-company",
    path: "tax-company",
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/index/tax-company.vue")
  }
]
  },
  {
    name: "admin-company-id-update",
    path: "/admin/company/:id()/update",
    meta: update1LTOHk5N6bMeta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/admin/company/[id]/update.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexyaE5b5nHEYMeta || {},
    component: () => import("/opt/buildhome/repo/layers/adminPanel/pages/admin/index.vue")
  },
  {
    name: "admin-master-bank",
    path: "/admin/master/bank",
    meta: index8l7PQr8psKMeta || {},
    component: () => import("/opt/buildhome/repo/layers/master/pages/admin/master/bank/index.vue")
  },
  {
    name: "admin-master-city",
    path: "/admin/master/city",
    meta: indexRJojE6eHXCMeta || {},
    component: () => import("/opt/buildhome/repo/layers/master/pages/admin/master/city/index.vue")
  },
  {
    name: "admin-master-country",
    path: "/admin/master/country",
    meta: index6jrKgtwk0wMeta || {},
    component: () => import("/opt/buildhome/repo/layers/master/pages/admin/master/country/index.vue")
  },
  {
    name: "admin-master-currency",
    path: "/admin/master/currency",
    meta: indexOooUlGok6FMeta || {},
    component: () => import("/opt/buildhome/repo/layers/master/pages/admin/master/currency/index.vue")
  },
  {
    name: "admin-master-province",
    path: "/admin/master/province",
    meta: indexuqyetRLMZ0Meta || {},
    component: () => import("/opt/buildhome/repo/layers/master/pages/admin/master/province/index.vue")
  },
  {
    name: "admin-master-questioner",
    path: "/admin/master/questioner",
    meta: indexaSvjlz3OfDMeta || {},
    component: () => import("/opt/buildhome/repo/layers/master/pages/admin/master/questioner/index.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: indexzcAqnt2IUzMeta || {},
    component: () => import("/opt/buildhome/repo/layers/adminPanel/pages/admin/users/index.vue")
  },
  {
    name: "application-calculator-pph-21",
    path: "/application/calculator-pph-21",
    meta: indexnJv89sdaRWMeta || {},
    component: () => import("/opt/buildhome/repo/layers/application/pages/application/calculator-pph-21/index.vue")
  },
  {
    name: "application-income-tax",
    path: "/application/income-tax",
    meta: indexUnUxFYn2eoMeta || {},
    component: () => import("/opt/buildhome/repo/layers/application/pages/application/income-tax/index.vue")
  },
  {
    name: "application-tax-slip-id",
    path: "/application/tax-slip/:id()",
    meta: indexlsjjncMD4UMeta || {},
    component: () => import("/opt/buildhome/repo/layers/application/pages/application/tax-slip/[id]/index.vue")
  },
  {
    name: "application-tax-slip",
    path: "/application/tax-slip",
    component: () => import("/opt/buildhome/repo/layers/application/pages/application/tax-slip/index.vue")
  },
  {
    name: "asset-management-asset-location",
    path: "/asset-management/asset-location",
    meta: indexW5NqhcVkNHMeta || {},
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/asset-location/index.vue")
  },
  {
    name: "asset-management-asset-type",
    path: "/asset-management/asset-type",
    meta: indexZ17SM3n9fVMeta || {},
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/asset-type/index.vue")
  },
  {
    name: indexgVz1MM0tIzMeta?.name,
    path: "/asset-management/asset/:id()",
    meta: indexgVz1MM0tIzMeta || {},
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/asset/[id]/index.vue"),
    children: [
  {
    name: "asset-management-asset-id-index-contacts",
    path: "contacts",
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/asset/[id]/index/contacts.vue")
  },
  {
    name: "asset-management-asset-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/asset/[id]/index/index.vue")
  },
  {
    name: "asset-management-asset-id-index-notes",
    path: "notes",
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/asset/[id]/index/notes.vue")
  }
]
  },
  {
    name: "asset-management-asset-id-update",
    path: "/asset-management/asset/:id()/update",
    meta: update6wUgf2io3tMeta || {},
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/asset/[id]/update.vue")
  },
  {
    name: "asset-management-asset-create",
    path: "/asset-management/asset/create",
    meta: create9zk3VBMCKQMeta || {},
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/asset/create.vue")
  },
  {
    name: "asset-management-asset",
    path: "/asset-management/asset",
    meta: indexwGuTHjXa2NMeta || {},
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/asset/index.vue")
  },
  {
    name: "asset-management-detail-code",
    path: "/asset-management/detail/:code()",
    meta: indexuayxjjsxswMeta || {},
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/detail/[code]/index.vue")
  },
  {
    name: indexiPQFmj5YZ8Meta?.name,
    path: "/asset-management/vendor/:id()",
    meta: indexiPQFmj5YZ8Meta || {},
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/vendor/[id]/index.vue"),
    children: [
  {
    name: "asset-management-vendor-id-index-accounts",
    path: "accounts",
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/vendor/[id]/index/accounts.vue")
  },
  {
    name: "asset-management-vendor-id-index-address",
    path: "address",
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/vendor/[id]/index/address.vue")
  },
  {
    name: "asset-management-vendor-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/vendor/[id]/index/index.vue")
  }
]
  },
  {
    name: "asset-management-vendor",
    path: "/asset-management/vendor",
    meta: indexAlf3oIFm7rMeta || {},
    component: () => import("/opt/buildhome/repo/layers/asset/pages/asset-management/vendor/index.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordIAC9O4ui0NMeta || {},
    component: () => import("/opt/buildhome/repo/layers/auth/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: logine3cetUJ75gMeta || {},
    component: () => import("/opt/buildhome/repo/layers/auth/pages/auth/login.vue")
  },
  {
    name: "auth-password-reset-token",
    path: "/auth/password/reset/:token()",
    meta: _91token_93dIirawLSgkMeta || {},
    component: () => import("/opt/buildhome/repo/layers/auth/pages/auth/password/reset/[token].vue")
  },
  {
    name: index3yLO2UGHHKMeta?.name,
    path: "/company/:id()",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index.vue"),
    children: [
  {
    name: "company-id-index-bank-account",
    path: "bank-account",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/bank-account.vue")
  },
  {
    name: "company-id-index-clients",
    path: "clients",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/clients.vue")
  },
  {
    name: "company-id-index-department",
    path: "department",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/department.vue")
  },
  {
    name: "company-id-index-employees",
    path: "employees",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/employees.vue")
  },
  {
    name: "company-id-index-estimate-cost",
    path: "estimate-cost",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/estimate-cost.vue")
  },
  {
    name: "company-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/index.vue")
  },
  {
    name: "company-id-index-logo",
    path: "logo",
    meta: logoHJz8AWDJ8NMeta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/logo.vue")
  },
  {
    name: "company-id-index-milestone-template",
    path: "milestone-template",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/milestone-template.vue")
  },
  {
    name: "company-id-index-position",
    path: "position",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/position.vue")
  },
  {
    name: "company-id-index-project-flow",
    path: "project-flow",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/project-flow.vue")
  },
  {
    name: "company-id-index-sales",
    path: "sales",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/sales.vue")
  },
  {
    name: "company-id-index-scopes",
    path: "scopes",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/scopes.vue")
  },
  {
    name: "company-id-index-services",
    path: "services",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/services.vue")
  },
  {
    name: "company-id-index-setting-create",
    path: "setting/create",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/setting/create.vue")
  },
  {
    name: "company-id-index-setting",
    path: "setting",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/setting/index.vue")
  },
  {
    name: "company-id-index-setting-update-settingId",
    path: "setting/update-:settingId()",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/setting/update-[settingId].vue")
  },
  {
    name: "company-id-index-tax-company",
    path: "tax-company",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/index/tax-company.vue")
  }
]
  },
  {
    name: "company-id-update",
    path: "/company/:id()/update",
    meta: update0puOwCjmwqMeta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/[id]/update.vue")
  },
  {
    name: "company-create",
    path: "/company/create",
    meta: createuw6twzNNmvMeta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/create.vue")
  },
  {
    name: "company-estimate-cost",
    path: "/company/estimate-cost",
    meta: estimate_45costSOH364ta9dMeta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/estimate-cost.vue")
  },
  {
    name: "company",
    path: "/company",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/index.vue")
  },
  {
    name: indexwIdbbxYIedMeta?.name,
    path: "/company/milestone-template/:id()",
    meta: indexwIdbbxYIedMeta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/milestone-template/[id]/index.vue"),
    children: [
  {
    name: "company-milestone-template-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/milestone-template/[id]/index/index.vue")
  },
  {
    name: "company-milestone-template-id-index-milestone",
    path: "milestone",
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/milestone-template/[id]/index/milestone.vue")
  }
]
  },
  {
    name: "company-milestone-template-create",
    path: "/company/milestone-template/create",
    meta: createQNLpTURzqfMeta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/milestone-template/create.vue")
  },
  {
    name: "company-project-status",
    path: "/company/project-status",
    meta: project_45status7JRh44DlqbMeta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/project-status.vue")
  },
  {
    name: "company-scope",
    path: "/company/scope",
    meta: scope7NsnhCauMhMeta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/scope.vue")
  },
  {
    name: "company-service-id",
    path: "/company/service/:id()",
    meta: indexOmgoQ7GuIRMeta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/service/[id]/index.vue")
  },
  {
    name: "company-service",
    path: "/company/service",
    meta: indexoXBDz8bHz1Meta || {},
    component: () => import("/opt/buildhome/repo/layers/company/pages/company/service/index.vue")
  },
  {
    name: indexSEhdEZkNMjMeta?.name,
    path: "/dashboard",
    component: () => import("/opt/buildhome/repo/pages/dashboard/index.vue"),
    children: [
  {
    name: "dashboard-index-charts",
    path: "charts",
    component: () => import("/opt/buildhome/repo/pages/dashboard/index/charts.vue")
  },
  {
    name: "dashboard-index",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/dashboard/index/index.vue")
  }
]
  },
  {
    name: indexFO4WuaLuArMeta?.name,
    path: "/direct-assignment/:id()",
    meta: indexFO4WuaLuArMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/direct-assignment/[id]/index.vue"),
    children: [
  {
    name: "direct-assignment-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/direct-assignment/[id]/index/index.vue")
  },
  {
    name: "direct-assignment-id-index-logs",
    path: "logs",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/direct-assignment/[id]/index/logs.vue")
  },
  {
    name: "direct-assignment-id-index-timesheet",
    path: "timesheet",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/direct-assignment/[id]/index/timesheet.vue")
  }
]
  },
  {
    name: "direct-assignment",
    path: "/direct-assignment",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/direct-assignment/index.vue")
  },
  {
    name: "finance-cash-advance-detail-id",
    path: "/finance/cash-advance/detail/:id()",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/cash-advance/detail/[id]/index.vue")
  },
  {
    name: indexhWD3PxpvgHMeta?.name,
    path: "/finance/cash-advance",
    meta: indexhWD3PxpvgHMeta || {},
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/cash-advance/index.vue"),
    children: [
  {
    name: "finance-cash-advance-index-category",
    path: ":category()",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/cash-advance/index/[category].vue")
  },
  {
    name: "finance-cash-advance-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/cash-advance/index/index.vue")
  }
]
  },
  {
    name: indexaVgCYtJbjVMeta?.name,
    path: "/finance/fund-application/:id()",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/fund-application/[id]/index.vue"),
    children: [
  {
    name: "finance-fund-application-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/fund-application/[id]/index/index.vue")
  }
]
  },
  {
    name: "finance-fund-application-id-update",
    path: "/finance/fund-application/:id()/update",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/fund-application/[id]/update.vue")
  },
  {
    name: "finance-fund-application-create",
    path: "/finance/fund-application/create",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/fund-application/create.vue")
  },
  {
    name: "finance-fund-application",
    path: "/finance/fund-application",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/fund-application/index.vue")
  },
  {
    name: indexo70k6PVg1JMeta?.name,
    path: "/finance",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/index.vue"),
    children: [
  {
    name: "finance-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/index/index.vue")
  },
  {
    name: "finance-index-reminder",
    path: "reminder",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/index/reminder.vue")
  },
  {
    name: "finance-index-summary",
    path: "summary",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/index/summary.vue")
  }
]
  },
  {
    name: "finance-invoice-counter",
    path: "/finance/invoice-counter",
    meta: indexNyLdAtVpN8Meta || {},
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/invoice-counter/index.vue")
  },
  {
    name: "finance-invoice-id-edit",
    path: "/finance/invoice/:id()/edit",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/invoice/[id]/edit.vue")
  },
  {
    name: index3uLZbQraghMeta?.name,
    path: "/finance/invoice/:id()",
    meta: index3uLZbQraghMeta || {},
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/invoice/[id]/index.vue"),
    children: [
  {
    name: "finance-invoice-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/invoice/[id]/index/index.vue")
  },
  {
    name: "finance-invoice-id-index-logs",
    path: "logs",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/invoice/[id]/index/logs.vue")
  },
  {
    name: "finance-invoice-id-index-reminder",
    path: "reminder",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/invoice/[id]/index/reminder.vue")
  }
]
  },
  {
    name: "finance-invoice-create",
    path: "/finance/invoice/create",
    meta: createUIKfhU7SVcMeta || {},
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/invoice/create.vue")
  },
  {
    name: indexzZGCRwUjVeMeta?.name,
    path: "/finance/invoice",
    meta: indexzZGCRwUjVeMeta || {},
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/invoice/index.vue"),
    children: [
  {
    name: "finance-invoice-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/invoice/index/index.vue")
  },
  {
    name: "finance-invoice-index-pending",
    path: "pending",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/invoice/index/pending.vue")
  },
  {
    name: "finance-invoice-index-settlement",
    path: "settlement",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/invoice/index/settlement.vue")
  }
]
  },
  {
    name: "finance-payments",
    path: "/finance/payments",
    meta: index4rkWY7l6KjMeta || {},
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/payments/index.vue")
  },
  {
    name: "finance-promo-id",
    path: "/finance/promo/:id()",
    meta: indexroLxf0VEzFMeta || {},
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/promo/[id]/index.vue")
  },
  {
    name: "finance-promo-create",
    path: "/finance/promo/create",
    meta: createlKjZGxMH9ZMeta || {},
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/promo/create.vue")
  },
  {
    name: "finance-promo",
    path: "/finance/promo",
    meta: indexOMLL5J00NEMeta || {},
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/promo/index.vue")
  },
  {
    name: indexS3obwFT0N1Meta?.name,
    path: "/finance/reimbursement/:id()",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/[id]/index.vue"),
    children: [
  {
    name: "finance-reimbursement-id-index-files",
    path: "files",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/[id]/index/files.vue")
  },
  {
    name: "finance-reimbursement-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/[id]/index/index.vue")
  },
  {
    name: "finance-reimbursement-id-index-logs",
    path: "logs",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/[id]/index/logs.vue")
  }
]
  },
  {
    name: "finance-reimbursement-id-update",
    path: "/finance/reimbursement/:id()/update",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/[id]/update.vue")
  },
  {
    name: "finance-reimbursement-create",
    path: "/finance/reimbursement/create",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/create.vue")
  },
  {
    name: "finance-reimbursement",
    path: "/finance/reimbursement",
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/reimbursement/index.vue")
  },
  {
    name: "finance-request-invoice",
    path: "/finance/request-invoice",
    meta: indexnlJsOlbbkXMeta || {},
    component: () => import("/opt/buildhome/repo/layers/finance/pages/finance/request-invoice/index.vue")
  },
  {
    name: "human-resource-announcements-id-update",
    path: "/human-resource/announcements/:id()/update",
    meta: updatepXgM3eL6beMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/announcements/[id]/update.vue")
  },
  {
    name: "human-resource-announcements-create",
    path: "/human-resource/announcements/create",
    meta: createYlCpQscd2HMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/announcements/create.vue")
  },
  {
    name: "human-resource-announcements",
    path: "/human-resource/announcements",
    meta: indexScsH0JMqqyMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/announcements/index.vue")
  },
  {
    name: "human-resource-appraisal",
    path: "/human-resource/appraisal",
    meta: indexE6XPzcNi8ZMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/appraisal/index.vue")
  },
  {
    name: "human-resource-career-id-applicants",
    path: "/human-resource/career/:id()/applicants",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/career/[id]/applicants.vue")
  },
  {
    name: "human-resource-career-id-update",
    path: "/human-resource/career/:id()/update",
    meta: updateg7AHjIXKf7Meta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/career/[id]/update.vue")
  },
  {
    name: "human-resource-career-applicant-id",
    path: "/human-resource/career/applicant/:id()",
    meta: indexJaXd43NeGmMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/career/applicant/[id]/index.vue")
  },
  {
    name: "human-resource-career-create",
    path: "/human-resource/career/create",
    meta: createLgz7s6QLMIMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/career/create.vue")
  },
  {
    name: "human-resource-career",
    path: "/human-resource/career",
    meta: indexovHX6yVkUIMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/career/index.vue")
  },
  {
    name: "human-resource-competency-id",
    path: "/human-resource/competency/:id()",
    meta: indexakNVN1KmKmMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/competency/[id]/index.vue")
  },
  {
    name: "human-resource-competency-assessment-id",
    path: "/human-resource/competency/assessment/:id()",
    meta: indexzxRDnhz6WLMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/competency/assessment/[id]/index.vue")
  },
  {
    name: "human-resource-competency-create",
    path: "/human-resource/competency/create",
    meta: createUlGSOiUS3uMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/competency/create.vue")
  },
  {
    name: "human-resource-competency",
    path: "/human-resource/competency",
    meta: indexPZREMzutrQMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/competency/index.vue")
  },
  {
    name: "human-resource-dashboard",
    path: "/human-resource/dashboard",
    meta: indexW0Rjh7Ahg1Meta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/dashboard/index.vue")
  },
  {
    name: "human-resource-department",
    path: "/human-resource/department",
    meta: indexUelPo4tFdNMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/department/index.vue")
  },
  {
    name: "human-resource-employee-status",
    path: "/human-resource/employee-status",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee-status/index.vue")
  },
  {
    name: indexPE88KaPF3kMeta?.name,
    path: "/human-resource/employee/:id()",
    meta: indexPE88KaPF3kMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index.vue"),
    children: [
  {
    name: "human-resource-employee-id-index-access-control",
    path: "access-control",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/access-control.vue")
  },
  {
    name: "human-resource-employee-id-index-bank-account",
    path: "bank-account",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/bank-account.vue")
  },
  {
    name: "human-resource-employee-id-index-certification",
    path: "certification",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/certification.vue")
  },
  {
    name: "human-resource-employee-id-index-contact-information",
    path: "contact-information",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/contact-information.vue")
  },
  {
    name: "human-resource-employee-id-index-education",
    path: "education",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/education.vue")
  },
  {
    name: "human-resource-employee-id-index-emergency-contact",
    path: "emergency-contact",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/emergency-contact.vue")
  },
  {
    name: "human-resource-employee-id-index",
    path: "",
    meta: indexnHrdqqYmexMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/index.vue")
  },
  {
    name: "human-resource-employee-id-index-indicator-assessor",
    path: "indicator-assessor",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/indicator-assessor.vue")
  },
  {
    name: "human-resource-employee-id-index-indicator",
    path: "indicator",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/indicator.vue")
  },
  {
    name: "human-resource-employee-id-index-overtime",
    path: "overtime",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/overtime.vue")
  },
  {
    name: "human-resource-employee-id-index-photo-signature",
    path: "photo-signature",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/photo-signature.vue")
  },
  {
    name: "human-resource-employee-id-index-positions",
    path: "positions",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/positions.vue")
  },
  {
    name: "human-resource-employee-id-index-report-overtime",
    path: "report-overtime",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/report-overtime.vue")
  },
  {
    name: "human-resource-employee-id-index-salary",
    path: "salary",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/salary.vue")
  },
  {
    name: "human-resource-employee-id-index-security",
    path: "security",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/security.vue")
  },
  {
    name: "human-resource-employee-id-index-tax-insurances",
    path: "tax-insurances",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/[id]/index/tax-insurances.vue")
  }
]
  },
  {
    name: "human-resource-employee-create",
    path: "/human-resource/employee/create",
    meta: createXlGcAPdeYEMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/create.vue")
  },
  {
    name: "human-resource-employee",
    path: "/human-resource/employee",
    meta: indexeJeGsDTXnfMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/employee/index.vue")
  },
  {
    name: "human-resource-goal-calendar",
    path: "/human-resource/goal-calendar",
    meta: indexGz3963p50fMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/goal-calendar/index.vue")
  },
  {
    name: "human-resource-goal-type",
    path: "/human-resource/goal-type",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/goal-type/index.vue")
  },
  {
    name: "human-resource-holidays",
    path: "/human-resource/holidays",
    meta: indexu5Z2TqYMH8Meta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/holidays/index.vue")
  },
  {
    name: "human-resource-indicator-id",
    path: "/human-resource/indicator/:id()",
    meta: indexqaMJF7i32fMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/indicator/[id]/index.vue")
  },
  {
    name: "human-resource-indicator",
    path: "/human-resource/indicator",
    meta: index8aggDl4ziZMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/indicator/index.vue")
  },
  {
    name: "human-resource-organizational-chart",
    path: "/human-resource/organizational-chart",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/organizational-chart/index.vue")
  },
  {
    name: indexn4qaqvJ0q3Meta?.name,
    path: "/human-resource/overtime/:id()",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/overtime/[id]/index.vue"),
    children: [
  {
    name: "human-resource-overtime-id-index-hr-validation",
    path: "hr-validation",
    meta: hr_45validationAYGkTmnU4vMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/overtime/[id]/index/hr-validation.vue")
  },
  {
    name: "human-resource-overtime-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/overtime/[id]/index/index.vue")
  },
  {
    name: "human-resource-overtime-id-index-logs",
    path: "logs",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/overtime/[id]/index/logs.vue")
  }
]
  },
  {
    name: "human-resource-overtime",
    path: "/human-resource/overtime",
    meta: indexKtYOXm86ysMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/overtime/index.vue")
  },
  {
    name: "human-resource-position",
    path: "/human-resource/position",
    meta: indexzz5yhBVciwMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/position/index.vue")
  },
  {
    name: "human-resource-setting-indicator",
    path: "/human-resource/setting-indicator",
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/setting-indicator/index.vue")
  },
  {
    name: "human-resource-track-goals",
    path: "/human-resource/track-goals",
    meta: indexPCsfI3DlaJMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/human-resource/track-goals/index.vue")
  },
  {
    name: _91q_93yrNqXkKukSMeta?.name,
    path: "/inadata/glosarium/:q()",
    component: () => import("/opt/buildhome/repo/layers/inadata/pages/inadata/glosarium/[q].vue"),
    children: [
  {
    name: "inadata-glosarium-q",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/inadata/pages/inadata/glosarium/[q]/index.vue")
  }
]
  },
  {
    name: "inadata-glosarium",
    path: "/inadata/glosarium",
    component: () => import("/opt/buildhome/repo/layers/inadata/pages/inadata/glosarium/index.vue")
  },
  {
    name: "inadata-peraturan-pajak",
    path: "/inadata/peraturan-pajak",
    component: () => import("/opt/buildhome/repo/layers/inadata/pages/inadata/peraturan-pajak/index.vue")
  },
  {
    name: "inapintar-chat-rag-ayat",
    path: "/inapintar/chat-rag-ayat",
    meta: indexeqefniI6d9Meta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/chat-rag-ayat/index.vue")
  },
  {
    name: "inapintar-chat-rag",
    path: "/inapintar/chat-rag",
    meta: index4fCyP9yOORMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/chat-rag/index.vue")
  },
  {
    name: "inapintar-chat-stream-id",
    path: "/inapintar/chat-stream/:id()",
    meta: index42HtzguMpdMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/chat-stream/[id]/index.vue")
  },
  {
    name: "inapintar-chat-stream",
    path: "/inapintar/chat-stream",
    meta: indexaxBk4vR1rbMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/chat-stream/index.vue")
  },
  {
    name: "inapintar-chat",
    path: "/inapintar/chat",
    meta: indexSm6Xeb8IfmMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/chat/index.vue")
  },
  {
    name: "inapintar-master-system-prompt",
    path: "/inapintar/master-system-prompt",
    meta: indexA50OUKAz6rMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/master-system-prompt/index.vue")
  },
  {
    name: "inapintar-score-detail-id",
    path: "/inapintar/score-detail/:id()",
    meta: indexYFfzk4XiAlMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/score-detail/[id]/index.vue")
  },
  {
    name: "inapintar-score-id",
    path: "/inapintar/score/:id()",
    meta: indexgVwSrKVdBCMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/score/[id]/index.vue")
  },
  {
    name: "inapintar-score",
    path: "/inapintar/score",
    meta: indexASnXqmd1j9Meta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/score/index.vue")
  },
  {
    name: "inapintar-scoring-chat-pasal-ayat-v2-id",
    path: "/inapintar/scoring-chat-pasal-ayat-v2/:id()",
    meta: indexUz9ayCrcZiMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-chat-pasal-ayat-v2/[id]/index.vue")
  },
  {
    name: "inapintar-scoring-chat-pasal-ayat-v2",
    path: "/inapintar/scoring-chat-pasal-ayat-v2",
    meta: indexAvckxVyvQrMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-chat-pasal-ayat-v2/index.vue")
  },
  {
    name: "inapintar-scoring-chat-pasal-ayat-id",
    path: "/inapintar/scoring-chat-pasal-ayat/:id()",
    meta: indexlizF0aACggMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-chat-pasal-ayat/[id]/index.vue")
  },
  {
    name: "inapintar-scoring-chat-pasal-ayat",
    path: "/inapintar/scoring-chat-pasal-ayat",
    meta: indexcu1ILCClYiMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-chat-pasal-ayat/index.vue")
  },
  {
    name: "inapintar-scoring-chat-id",
    path: "/inapintar/scoring-chat/:id()",
    meta: indexeM0t3dtcCGMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-chat/[id]/index.vue")
  },
  {
    name: "inapintar-scoring-chat",
    path: "/inapintar/scoring-chat",
    meta: indexSBz5GizIg8Meta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-chat/index.vue")
  },
  {
    name: "inapintar-scoring-question-id",
    path: "/inapintar/scoring-question/:id()",
    meta: indexqLmzEu50wEMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-question/[id]/index.vue")
  },
  {
    name: "inapintar-scoring-question",
    path: "/inapintar/scoring-question",
    meta: indexT2LHTevMoiMeta || {},
    component: () => import("/opt/buildhome/repo/layers/inapintar/pages/inapintar/scoring-question/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: indexuLES1ucib3Meta?.name,
    path: "/marketing/:category()/:id()",
    meta: indexuLES1ucib3Meta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index.vue"),
    children: [
  {
    name: "marketing-category-id-index-address",
    path: "address",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/address.vue")
  },
  {
    name: "marketing-category-id-index-contacts",
    path: "contacts",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/contacts.vue")
  },
  {
    name: "marketing-category-id-index-files",
    path: "files",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/files.vue")
  },
  {
    name: "marketing-category-id-index-financial",
    path: "financial",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/financial.vue")
  },
  {
    name: "marketing-category-id-index",
    path: "",
    meta: indexW1oVlz3Tg9Meta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/index.vue")
  },
  {
    name: "marketing-category-id-index-invoices",
    path: "invoices",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/invoices.vue")
  },
  {
    name: "marketing-category-id-index-notes",
    path: "notes",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/notes.vue")
  },
  {
    name: "marketing-category-id-index-projects-status",
    path: "projects/:status()",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/projects/[status]/index.vue")
  },
  {
    name: "marketing-category-id-index-questioner",
    path: "questioner",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/[id]/index/questioner.vue")
  }
]
  },
  {
    name: "marketing-category",
    path: "/marketing/:category()",
    meta: indexG3Me9oOE4vMeta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/[category]/index.vue")
  },
  {
    name: "marketing-dashboard",
    path: "/marketing/dashboard",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/dashboard/index.vue")
  },
  {
    name: indexSAf3ZsxsBiMeta?.name,
    path: "/marketing/sales/:id()",
    meta: indexSAf3ZsxsBiMeta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/sales/[id]/index.vue"),
    children: [
  {
    name: "marketing-sales-id-index-clients",
    path: "clients",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/sales/[id]/index/clients.vue")
  },
  {
    name: "marketing-sales-id-index-fees",
    path: "fees",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/sales/[id]/index/fees.vue")
  },
  {
    name: "marketing-sales-id-index",
    path: "",
    meta: indexhlwOyoT7VDMeta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/sales/[id]/index/index.vue")
  },
  {
    name: "marketing-sales-id-index-projects-status",
    path: "projects/:status()",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/sales/[id]/index/projects/[status]/index.vue")
  }
]
  },
  {
    name: "marketing-sales",
    path: "/marketing/sales",
    meta: indexrD1P5FYL33Meta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/sales/index.vue")
  },
  {
    name: "marketing-seminar-create",
    path: "/marketing/seminar/create",
    meta: creategjCqjm1kqjMeta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/create.vue")
  },
  {
    name: index59l9QDy70KMeta?.name,
    path: "/marketing/seminar/detail/:id()",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/detail/[id]/index.vue"),
    children: [
  {
    name: "marketing-seminar-detail-id-index-additional",
    path: "additional",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/detail/[id]/index/additional.vue")
  },
  {
    name: "marketing-seminar-detail-id-index-email-marketing",
    path: "email-marketing",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/detail/[id]/index/email-marketing.vue")
  },
  {
    name: "marketing-seminar-detail-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/detail/[id]/index/index.vue")
  },
  {
    name: "marketing-seminar-detail-id-index-invoices",
    path: "invoices",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/detail/[id]/index/invoices.vue")
  },
  {
    name: "marketing-seminar-detail-id-index-participant",
    path: "participant",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/detail/[id]/index/participant.vue")
  }
]
  },
  {
    name: "marketing-seminar",
    path: "/marketing/seminar",
    meta: indexM1hWScGAoeMeta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/index.vue")
  },
  {
    name: "marketing-seminar-update-id",
    path: "/marketing/seminar/update/:id()",
    meta: indexDBgBGhK2N2Meta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/seminar/update/[id]/index.vue")
  },
  {
    name: "marketing-speaker-host-create",
    path: "/marketing/speaker-host/create",
    meta: creater4J33WROgFMeta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/speaker-host/create.vue")
  },
  {
    name: "marketing-speaker-host",
    path: "/marketing/speaker-host",
    meta: indexhEarXdVIxqMeta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/speaker-host/index.vue")
  },
  {
    name: "marketing-speaker-host-update-id",
    path: "/marketing/speaker-host/update/:id()",
    meta: index1ULUcSHXtnMeta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/speaker-host/update/[id]/index.vue")
  },
  {
    name: index4Wp1W9FhKhMeta?.name,
    path: "/marketing/template-questioner/:id()",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/template-questioner/[id]/index.vue"),
    children: [
  {
    name: "marketing-template-questioner-id-index-create",
    path: "create",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/template-questioner/[id]/index/create.vue")
  },
  {
    name: "marketing-template-questioner-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/template-questioner/[id]/index/index.vue")
  },
  {
    name: "marketing-template-questioner-id-index-update",
    path: "update",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/template-questioner/[id]/index/update.vue")
  }
]
  },
  {
    name: "marketing-template-questioner",
    path: "/marketing/template-questioner",
    meta: indexEuSBK0zwATMeta || {},
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/template-questioner/index.vue")
  },
  {
    name: "marketing-template-questioner-list-update-id",
    path: "/marketing/template-questioner/list/update/:id()",
    component: () => import("/opt/buildhome/repo/layers/marketing/pages/marketing/template-questioner/list/update/[id].vue")
  },
  {
    name: "media-manager",
    path: "/media-manager",
    meta: indexWEePdDEV7OMeta || {},
    component: () => import("/opt/buildhome/repo/pages/media-manager/index.vue")
  },
  {
    name: "meeting",
    path: "/meeting",
    meta: indexTEcToEq4dyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/meeting/index.vue")
  },
  {
    name: "monitoring-consultation",
    path: "/monitoring/consultation",
    component: () => import("/opt/buildhome/repo/pages/monitoring/consultation/index.vue")
  },
  {
    name: "monitoring-project",
    path: "/monitoring/project",
    component: () => import("/opt/buildhome/repo/pages/monitoring/project/index.vue")
  },
  {
    name: "overtime-letter",
    path: "/overtime-letter",
    meta: indexBtXDnxJmeMMeta || {},
    component: () => import("/opt/buildhome/repo/layers/humanResource/pages/overtime-letter/index.vue")
  },
  {
    name: "partner-account",
    path: "/partner/account",
    meta: indexAHQgiWSSTNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/partner/account/index.vue"),
    children: [
  {
    name: "partner-account-index-profile",
    path: "profile",
    meta: profileBmmLCa6mlgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/partner/account/index/profile.vue")
  },
  {
    name: "partner-account-index-security",
    path: "security",
    component: () => import("/opt/buildhome/repo/pages/partner/account/index/security.vue")
  }
]
  },
  {
    name: indexw52uLYNcftMeta?.name,
    path: "/partner/client/:id()",
    meta: indexw52uLYNcftMeta || {},
    component: () => import("/opt/buildhome/repo/pages/partner/client/[id]/index.vue"),
    children: [
  {
    name: "partner-client-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/partner/client/[id]/index/index.vue")
  },
  {
    name: "partner-client-id-index-invoice",
    path: "invoice",
    component: () => import("/opt/buildhome/repo/pages/partner/client/[id]/index/invoice.vue")
  },
  {
    name: "partner-client-id-index-project",
    path: "project",
    component: () => import("/opt/buildhome/repo/pages/partner/client/[id]/index/project.vue")
  }
]
  },
  {
    name: "partner-client",
    path: "/partner/client",
    meta: indexHee23xTo2WMeta || {},
    component: () => import("/opt/buildhome/repo/pages/partner/client/index.vue")
  },
  {
    name: "partner",
    path: "/partner",
    meta: index3OZP5C9nzFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/partner/index.vue")
  },
  {
    name: "partner-invoice-id",
    path: "/partner/invoice/:id()",
    meta: indexozfrOoOxMeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/partner/invoice/[id]/index.vue")
  },
  {
    name: "partner-invoice",
    path: "/partner/invoice",
    meta: indexiwfqVNFnNxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/partner/invoice/index.vue")
  },
  {
    name: indexrVgBoXBJvQMeta?.name,
    path: "/partner/project/:id()",
    meta: indexrVgBoXBJvQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/partner/project/[id]/index.vue"),
    children: [
  {
    name: "partner-project-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/partner/project/[id]/index/index.vue")
  },
  {
    name: "partner-project-id-index-invoice",
    path: "invoice",
    component: () => import("/opt/buildhome/repo/pages/partner/project/[id]/index/invoice.vue")
  }
]
  },
  {
    name: "partner-project",
    path: "/partner/project",
    meta: indexsogoTtfzPcMeta || {},
    component: () => import("/opt/buildhome/repo/pages/partner/project/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policypnUDKF1OY5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue")
  },
  {
    name: "project-create",
    path: "/project/create",
    meta: createeezDaq7Om5Meta || {},
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/create.vue")
  },
  {
    name: indexS88EPSYaLHMeta?.name,
    path: "/project/dashboard",
    meta: indexS88EPSYaLHMeta || {},
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/dashboard/index.vue"),
    children: [
  {
    name: "project-dashboard-index-assignment",
    path: "assignment",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/dashboard/index/assignment.vue")
  },
  {
    name: "project-dashboard-index-change-manager",
    path: "change-manager",
    meta: change_45managerSxu3a3ff0YMeta || {},
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/dashboard/index/change-manager.vue")
  },
  {
    name: "project-dashboard-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/dashboard/index/index.vue")
  },
  {
    name: "project-dashboard-index-review",
    path: "review",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/dashboard/index/review.vue")
  },
  {
    name: "project-dashboard-index-term-of-payment",
    path: "term-of-payment",
    meta: term_45of_45paymentngcUnRIMHHMeta || {},
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/dashboard/index/term-of-payment.vue")
  }
]
  },
  {
    name: indexaC83OSx0KcMeta?.name,
    path: "/project/detail/:id()",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index.vue"),
    children: [
  {
    name: contracteL0zmbOlquMeta?.name,
    path: "contract",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/contract.vue"),
    children: [
  {
    name: "project-detail-id-index-contract-files",
    path: "files",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/contract/files.vue")
  },
  {
    name: "project-detail-id-index-contract",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/contract/index.vue")
  }
]
  },
  {
    name: "project-detail-id-index-direct-assignment",
    path: "direct-assignment",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/direct-assignment.vue")
  },
  {
    name: "project-detail-id-index-due-date-history",
    path: "due-date-history",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/due-date-history.vue")
  },
  {
    name: "project-detail-id-index-estimate-cost",
    path: "estimate-cost",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/estimate-cost.vue")
  },
  {
    name: "project-detail-id-index-estimate-timer",
    path: "estimate-timer",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/estimate-timer.vue")
  },
  {
    name: "project-detail-id-index-external-issue",
    path: "external-issue",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/external-issue.vue")
  },
  {
    name: "project-detail-id-index-files",
    path: "files",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/files.vue")
  },
  {
    name: "project-detail-id-index-follow-up",
    path: "follow-up",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/follow-up.vue")
  },
  {
    name: "project-detail-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/index.vue")
  },
  {
    name: "project-detail-id-index-internal-issue",
    path: "internal-issue",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/internal-issue.vue")
  },
  {
    name: "project-detail-id-index-invoice",
    path: "invoice",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/invoice.vue")
  },
  {
    name: "project-detail-id-index-logs",
    path: "logs",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/logs.vue")
  },
  {
    name: "project-detail-id-index-milestone",
    path: "milestone",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/milestone.vue")
  },
  {
    name: "project-detail-id-index-project-fee",
    path: "project-fee",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/project-fee.vue")
  },
  {
    name: "project-detail-id-index-realization",
    path: "realization",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/realization.vue")
  },
  {
    name: "project-detail-id-index-teams",
    path: "teams",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/teams.vue")
  },
  {
    name: "project-detail-id-index-term-of-payment",
    path: "term-of-payment",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/term-of-payment.vue")
  },
  {
    name: "project-detail-id-index-timesheet",
    path: "timesheet",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/detail/[id]/index/timesheet.vue")
  }
]
  },
  {
    name: "project",
    path: "/project",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/index.vue")
  },
  {
    name: "project-listing-category",
    path: "/project/listing/:category()",
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/listing/[category]/index.vue")
  },
  {
    name: "project-recreate-id",
    path: "/project/recreate/:id()",
    meta: indextlzvNBSsHZMeta || {},
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/recreate/[id]/index.vue")
  },
  {
    name: "project-update-id-contract",
    path: "/project/update/:id()/contract",
    meta: contractmGesN2cWlDMeta || {},
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/update/[id]/contract.vue")
  },
  {
    name: "project-update-id",
    path: "/project/update/:id()",
    meta: indexLpjiUNU4E3Meta || {},
    component: () => import("/opt/buildhome/repo/layers/project/pages/project/update/[id]/index.vue")
  },
  {
    name: indextj75HGCzCKMeta?.name,
    path: "/report/monthly/:id()",
    meta: indextj75HGCzCKMeta || {},
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index.vue"),
    children: [
  {
    name: "report-monthly-id-index-asset",
    path: "asset",
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/asset.vue")
  },
  {
    name: "report-monthly-id-index-client",
    path: "client",
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/client.vue")
  },
  {
    name: "report-monthly-id-index-employment-status",
    path: "employment-status",
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/employment-status.vue")
  },
  {
    name: "report-monthly-id-index-financial-ratio",
    path: "financial-ratio",
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/financial-ratio.vue")
  },
  {
    name: "report-monthly-id-index-financial-statement",
    path: "financial-statement",
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/financial-statement.vue")
  },
  {
    name: "report-monthly-id-index-human-resource",
    path: "human-resource",
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/human-resource.vue")
  },
  {
    name: "report-monthly-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/index.vue")
  },
  {
    name: "report-monthly-id-index-profit-loss",
    path: "profit-loss",
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/profit-loss.vue")
  },
  {
    name: "report-monthly-id-index-project",
    path: "project",
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/project.vue")
  },
  {
    name: "report-monthly-id-index-quotation-report",
    path: "quotation-report",
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/quotation-report.vue")
  },
  {
    name: "report-monthly-id-index-source-client",
    path: "source-client",
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/source-client.vue")
  },
  {
    name: "report-monthly-id-index-workload",
    path: "workload",
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/[id]/index/workload.vue")
  }
]
  },
  {
    name: "report-monthly-create",
    path: "/report/monthly/create",
    meta: createaOi5C7YTwHMeta || {},
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/create.vue")
  },
  {
    name: "report-monthly",
    path: "/report/monthly",
    meta: indexzavOuph34bMeta || {},
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/monthly/index.vue")
  },
  {
    name: "report-potential-invoice",
    path: "/report/potential-invoice",
    meta: indexWNyUNAljT0Meta || {},
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/potential-invoice/index.vue")
  },
  {
    name: "report-progress-project",
    path: "/report/progress-project",
    meta: indexse8QvnGpyQMeta || {},
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/progress-project/index.vue")
  },
  {
    name: "report-project-marginal-margin",
    path: "/report/project-marginal-margin",
    meta: indexZ8uSEcKsgYMeta || {},
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/project-marginal-margin/index.vue")
  },
  {
    name: "report-project-per-status",
    path: "/report/project-per-status",
    meta: indexMJ36Z2kymsMeta || {},
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/project-per-status/index.vue")
  },
  {
    name: "report-timer-consultant-per-project",
    path: "/report/timer-consultant-per-project",
    meta: indexHT1WkThDZKMeta || {},
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/timer-consultant-per-project/index.vue")
  },
  {
    name: "report-timer-consultant",
    path: "/report/timer-consultant",
    meta: indexTeHmSRNXKDMeta || {},
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/timer-consultant/index.vue")
  },
  {
    name: "report-timesheet",
    path: "/report/timesheet",
    meta: indexKTDClEWk8xMeta || {},
    component: () => import("/opt/buildhome/repo/layers/reports/pages/report/timesheet/index.vue")
  },
  {
    name: "restricted",
    path: "/restricted",
    meta: indexf6t7fZYjldMeta || {},
    component: () => import("/opt/buildhome/repo/pages/restricted/index.vue")
  },
  {
    name: "sleekflow-contact-list",
    path: "/sleekflow/contact-list",
    component: () => import("/opt/buildhome/repo/pages/sleekflow/contact-list.vue")
  },
  {
    name: "support-knowledge-base",
    path: "/support/knowledge-base",
    meta: indexqSRR0bar1OMeta || {},
    component: () => import("/opt/buildhome/repo/pages/support/knowledge-base/index.vue")
  },
  {
    name: "support-package",
    path: "/support/package",
    meta: indextOLgD3uqShMeta || {},
    component: () => import("/opt/buildhome/repo/pages/support/package/index.vue")
  },
  {
    name: "support-package-send-document",
    path: "/support/package/send-document",
    component: () => import("/opt/buildhome/repo/pages/support/package/send-document/index.vue")
  },
  {
    name: "support-package-track-id",
    path: "/support/package/track/:id()",
    component: () => import("/opt/buildhome/repo/pages/support/package/track/[id].vue")
  },
  {
    name: "support-ticket",
    path: "/support/ticket",
    meta: indexXsoWST261bMeta || {},
    component: () => import("/opt/buildhome/repo/pages/support/ticket/index.vue")
  },
  {
    name: "tax-calculation-calculator-pph-21",
    path: "/tax-calculation/calculator-pph-21",
    meta: indexyIKtxfYOXPMeta || {},
    component: () => import("/opt/buildhome/repo/layers/taxCalculation/pages/tax-calculation/calculator-pph-21/index.vue")
  },
  {
    name: "tax-calculation-excel-template-id",
    path: "/tax-calculation/excel-template/:id()",
    meta: indexhVCFM4HuAcMeta || {},
    component: () => import("/opt/buildhome/repo/layers/taxCalculation/pages/tax-calculation/excel-template/[id]/index.vue")
  },
  {
    name: "tax-calculation-excel-template",
    path: "/tax-calculation/excel-template",
    meta: indexLp6vrLMxKDMeta || {},
    component: () => import("/opt/buildhome/repo/layers/taxCalculation/pages/tax-calculation/excel-template/index.vue")
  },
  {
    name: "tax-calculation-upload-excel-id",
    path: "/tax-calculation/upload-excel/:id()",
    meta: indexysV1bGWFnGMeta || {},
    component: () => import("/opt/buildhome/repo/layers/taxCalculation/pages/tax-calculation/upload-excel/[id]/index.vue")
  },
  {
    name: "tax-calculation-worksheet-id",
    path: "/tax-calculation/worksheet/:id()",
    meta: index94pmFVrvPbMeta || {},
    component: () => import("/opt/buildhome/repo/layers/taxCalculation/pages/tax-calculation/worksheet/[id]/index.vue")
  },
  {
    name: "tax-calculation-worksheet",
    path: "/tax-calculation/worksheet",
    meta: indexaHVOQwKkSIMeta || {},
    component: () => import("/opt/buildhome/repo/layers/taxCalculation/pages/tax-calculation/worksheet/index.vue")
  },
  {
    name: "website-expert-id",
    path: "/website/expert/:id()",
    meta: indextsLWkiYxEmMeta || {},
    component: () => import("/opt/buildhome/repo/layers/website/pages/website/expert/[id]/index.vue")
  },
  {
    name: "website-expert-create",
    path: "/website/expert/create",
    meta: createTQeRQpa218Meta || {},
    component: () => import("/opt/buildhome/repo/layers/website/pages/website/expert/create.vue")
  },
  {
    name: "website-expert",
    path: "/website/expert",
    meta: indexv3iIqV3gKtMeta || {},
    component: () => import("/opt/buildhome/repo/layers/website/pages/website/expert/index.vue")
  },
  {
    name: "website-page-seo",
    path: "/website/page-seo",
    component: () => import("/opt/buildhome/repo/layers/website/pages/website/page-seo/index.vue")
  },
  {
    name: "website-post-id",
    path: "/website/post/:id()",
    meta: index0b3oVSBEJPMeta || {},
    component: () => import("/opt/buildhome/repo/layers/website/pages/website/post/[id]/index.vue")
  },
  {
    name: "website-post-create",
    path: "/website/post/create",
    meta: createDpdNZxzZXwMeta || {},
    component: () => import("/opt/buildhome/repo/layers/website/pages/website/post/create.vue")
  },
  {
    name: "website-post",
    path: "/website/post",
    meta: indexlAgOXvDkbNMeta || {},
    component: () => import("/opt/buildhome/repo/layers/website/pages/website/post/index.vue")
  },
  {
    name: "website-service-id",
    path: "/website/service/:id()",
    meta: indexEHOlfKjPwJMeta || {},
    component: () => import("/opt/buildhome/repo/layers/website/pages/website/service/[id]/index.vue")
  },
  {
    name: "website-service-create",
    path: "/website/service/create",
    meta: createWKFZmdiTvrMeta || {},
    component: () => import("/opt/buildhome/repo/layers/website/pages/website/service/create.vue")
  },
  {
    name: "website-service",
    path: "/website/service",
    meta: indexj8wcaRsiARMeta || {},
    component: () => import("/opt/buildhome/repo/layers/website/pages/website/service/index.vue")
  },
  {
    name: "widget-pajakumkm-form-checkout",
    path: "/widget/pajakumkm/form-checkout",
    meta: form_45checkoutHYkPTWlhVXMeta || {},
    component: () => import("/opt/buildhome/repo/pages/widget/pajakumkm/form-checkout.vue")
  },
  {
    name: "widget-pajakumkm-pay-id",
    path: "/widget/pajakumkm/pay/:id()",
    meta: indexWgcMtZt4AIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/widget/pajakumkm/pay/[id]/index.vue")
  }
]