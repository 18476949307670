import type { IResponder } from "~/interfaces/iResponder"

export default async function useSave(
  url: string,
  body: any,
  options: any,
): Promise<IResponder> {
  if (options?.formType === "update") {
    body["_method"] = "PUT"
    url = `${url}/${options.id}`
  }
  return (await useApiFetch(`${url}`, {
    method: "POST",
    body: body,
  })) as IResponder
}
