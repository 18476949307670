export default defineNuxtPlugin(() => {
  return {
    provide: {
      currencyIDR: (value: number): string =>
        "Rp " + new Intl.NumberFormat("id-ID").format(value),
      numberFormat: (value: number): string =>
        new Intl.NumberFormat("id-ID").format(value),
    },
  }
})
