export const announcement = "/human-resource/announcement"
export const appraisal = "/human-resource/appraisal"
export const career = "/human-resource/career"
export const department = "/human-resource/department"
export const dashboard = "/human-resource/dashboard"
export const employeeStatus = "/human-resource/employee-status"
export const holiday = "/human-resource/holiday"
export const indicator = "/human-resource/indicator"
export const competency = "/human-resource/competency"
export const goalCalendar = "/human-resource/goal-calendar"
export const goalType = "/human-resource/goal-type"
export const settingIndicator = "/human-resource/setting-indicator"
export const trackGoal = "/human-resource/track-goals"
export const employee = "/human-resource/employee"
export const employeeAssessor = "/human-resource/employee-assessor"
export const employeeSalary = "/human-resource/employee-salary"
export const employeeEducation = "/human-resource/employee-education"
export const employeeCertification = "/human-resource/employee-certification"
export const employeeEmergencyContact =
  "/human-resource/employee-emergency-contact"
export const position = "/human-resource/position"
export const overtime = "/human-resource/overtime"
export const overtimeLetter = "/human-resource/overtime-letter"
