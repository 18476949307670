export default defineAppConfig({
  tairo: {
    title: "Monitoring V3",
    error: {
      logo: {
        component: "Logo",
        props: {
          class: "text-primary-500 mx-auto h-40 p-6",
        },
      },
    },
    collapse: {
      circularMenu: {
        enabled: false,
        tools: [],
      },
      toolbar: {
        enabled: true,
        showTitle: true,
        showNavBurger: true,
        tools: [
          {
            component: "CompanyBar",
          },
          {
            component: "UserNotification",
          },
          {
            component: "TimerNotification",
          },
          {
            component: "ThemeToggle",
          },
          {
            component: "ToolbarAccountMenu",
          },
        ],
      },
      navigation: {
        enabled: true,
        header: {
          component: "",
        },
        footer: {
          component: "",
        },
      },
    },
  },
  nui: {
    BaseCard: {
      rounded: "lg",
    },
    BaseButton: {
      rounded: "lg",
      size: "sm",
      color: "primary",
    },
    BaseButtonAction: {
      rounded: "lg",
      size: "sm",
    },
    BaseInput: {
      rounded: "lg",
    },
    BaseTextarea: {
      rounded: "lg",
    },
    BaseTag: {
      rounded: "lg",
    },
    BaseMessage: {
      rounded: "lg",
    },
  },
})
