import type { IPermissionOptions } from "~/interfaces/iPermissionOptions"
import useAccessControl from "~/layers/accessControl/composables/useAccessControl"

export default defineNuxtPlugin((nuxtApp) => {
  const { redirectIfNotAllowed } = useRuntimeConfig().public
    .permissions as IPermissionOptions
  const { data } = useAuth()
  const userRoles = data.value?.roles
  const userPermissions = data.value?.permissions
  const { hasFullAccessRole } = useAccessControl()

  function hasRequiredPermissions(permissions: string | string[] | undefined) {
    if (!permissions) return

    const routePermissions =
      typeof permissions === "string" ? [permissions] : permissions

    return routePermissions.some((permission: string) => {
      return userPermissions?.includes(permission)
    })
  }

  function hasRequiredRoles(roles: string | string[] | undefined) {
    if (!roles) return
    const myRoles = typeof roles === "string" ? [roles] : roles

    return myRoles.some((role) => userRoles?.includes(role))
  }

  addRouteMiddleware("nuxt-permissions", (to, from) => {
    const routePermissions = to.meta?.permissions as
      | string
      | string[]
      | undefined

    const routeRoles = to.meta?.roles as string | string[] | undefined

    if (!routePermissions && !routeRoles) {
      return true
    }

    if (hasFullAccessRole.value) {
      return true
    }

    if (routePermissions && hasRequiredPermissions(routePermissions)) {
      return true
    }

    if (routeRoles && hasRequiredRoles(routeRoles)) {
      return true
    }

    if (!redirectIfNotAllowed) {
      if (from.fullPath !== to.fullPath) {
        return from.fullPath
      }
      return false
    }

    return redirectIfNotAllowed || "/"
  })

  function hasNotPermission(binding: string | string[] | undefined) {
    if (!binding) return true
    if (hasFullAccessRole.value) {
      return true
    }
    const activePermissions = typeof binding === "string" ? [binding] : binding
    return !activePermissions.some((permission) =>
      userPermissions?.includes(permission),
    )
  }

  function hasPermission(binding: string | string[]) {
    if (!binding) return true
    if (hasFullAccessRole.value) {
      return true
    }
    return !hasNotPermission(binding)
  }

  nuxtApp.vueApp.directive("can", {
    mounted(el, binding) {
      if (binding.arg === "not") {
        if (hasPermission(binding.value)) {
          el.remove()
        }
        return
      }
      if (!hasPermission(binding.value)) {
        el.remove()
      }
    },
  })

  function hasNotRole(binding: string | string[] | undefined) {
    if (!binding) return true
    if (hasFullAccessRole.value) {
      return true
    }
    const activeRoles = typeof binding === "string" ? [binding] : binding
    return !activeRoles.some((role) => userRoles?.includes(role))
  }

  function hasRole(binding: string | string[]) {
    if (!binding) return true
    if (hasFullAccessRole.value) {
      return true
    }
    return !hasNotRole(binding)
  }

  nuxtApp.vueApp.directive("role", {
    mounted(el, binding) {
      if (binding.arg === "not") {
        if (hasRole(binding.value)) {
          el.remove()
        }
        return
      }
      if (!hasRole(binding.value)) {
        el.remove()
      }
    },
  })

  return {
    provide: {
      hasRole,
      hasPermission,
    },
  }
})
