<script setup lang="ts">
const props = defineProps(["error"])
const handleError = () =>
  clearError({
    redirect: "/",
  })
const errorImage = computed(() => {
  return "/images/404.svg"
})
</script>

<template>
  <div class="h-full">
    <div
      class="min-h-screen flex flex-col justify-center items-center text-center py-20 bg-slate-900"
    >
      <img :src="errorImage" loading="lazy" alt="404 error" />
      <div class="max-w-[546px] mx-auto w-full mt-12">
        <h4 class="text-white text-[40px] leading-[50px] mb-4">
          {{ error.statusMessage }}
        </h4>
        <div
          v-if="error.statusCode === 404"
          class="text-white text-base font-normal mb-10"
        >
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </div>
        <div v-else class="text-white text-base font-normal mb-10">
          {{ error }}
        </div>
      </div>
      <div class="max-w-[300px] mx-auto w-full">
        <nuxt-link
          @click="handleError"
          class="btn bg-white hover:bg-opacity-75 transition-all duration-150 block text-center"
        >
          Go to homepage
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
