import { CkeditorPlugin } from "@ckeditor/ckeditor5-vue"
import Editor from "~/layers/richTextEditor/utils/CustomEditor"

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(CkeditorPlugin)
  return {
    provide: {
      ckeditor: {
        customEditor: Editor,
      },
    },
  }
})
