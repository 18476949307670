export const useTable = defineStore("table", {
  state: () => ({
    dataChanged: false,
    tableChanged: false,
  }),

  actions: {
    setDataChanged(val: boolean) {
      this.dataChanged = val
    },
    setTableChanged(val: boolean) {
      this.tableChanged = val
    },
  },
})
