export default function useAccessControl() {
  const { data } = useAuth()
  const userRoles = data.value?.roles
  const userPermissions = data.value?.permissions
  const { fullAccessRoles } = useRuntimeConfig().public.permissions

  const hasFullAccessRole = computed(() => {
    const fullAccess: string[] =
      typeof fullAccessRoles === "string" ? [fullAccessRoles] : fullAccessRoles

    return fullAccess && fullAccess.some((role) => userRoles?.includes(role))
  })

  const userCan = (permissions: string | string[] | undefined) => {
    if (!permissions) return

    if (hasFullAccessRole.value) {
      return true
    }

    const routePermissions: string[] =
      typeof permissions === "string" ? [permissions] : permissions

    return routePermissions.some((permission: string) => {
      return userPermissions?.includes(permission)
    })
  }

  const hasRoles = (
    roles: string | string[] | undefined,
    rolesParams: string[] | undefined = [],
  ) => {
    if (!roles) return
    const myRoles: string[] = typeof roles === "string" ? [roles] : roles
    const searchRoles: string[] | undefined =
      rolesParams?.length > 0 ? rolesParams : userRoles
    return myRoles.some((role: string) => searchRoles?.includes(role))
  }

  const superAdmin = computed(() => hasFullAccessRole.value)
  const director = computed(
    () => hasRoles("Director") || hasRoles("Managing Director"),
  )
  const marketing = computed(() =>
    hasRoles(["Supervisor Marketing", "Manager Marketing", "Senior Marketing"]),
  )
  const finance = computed(() =>
    hasRoles(["Supervisor Finance", "Manager Finance", "Senior Finance"]),
  )

  const manager = computed(() =>
    hasRoles([
      "Manager Finance",
      "Manager Marketing",
      "Manager Consultant",
      "Manager Hr",
    ]),
  )
  return {
    userCan,
    hasRoles,
    superAdmin,
    director,
    marketing,
    finance,
    manager,
    hasFullAccessRole,
  }
}
