export default function useApiFetch(
  path: string,
  options: any = {},
): Promise<any> {
  const { token } = useAuth()
  let headers: any = {
    accept: "application/json",
  }
  if (token.value) {
    headers["Authorization"] = token?.value
  }
  return $be(`${path}`, {
    ...options,
    headers: {
      ...headers,
      ...options?.headers,
    },
  })
}
